import React from 'react';
import CodeEditor from './component/codeEditor'; // Path to your component
import 'bootstrap/dist/css/bootstrap.min.css';
 
const App = () => {
  return (
    <div className="App">
      <h1 className="d-flex justify-content-center" color='blue'>CodeMirror Editor in React</h1>
      <CodeEditor />
    </div>
  );
};

export default App;
