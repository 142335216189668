import React, { useState, useEffect } from "react";
import axios from "axios";
import CodeMirror from "@uiw/react-codemirror";
import { createTheme } from "@uiw/codemirror-themes";
import { javascript } from "@codemirror/lang-javascript";
import { tags as t } from "@lezer/highlight";
import { loadLanguage } from "@uiw/codemirror-extensions-langs";
import { languages } from "@codemirror/language-data";
import { StreamLanguage } from "@codemirror/language";
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { less } from "@codemirror/lang-less";
import { sass } from "@codemirror/lang-sass";
import { json } from "@codemirror/lang-json";
import { mermaid } from "codemirror-lang-mermaid";
import { python } from "@codemirror/lang-python";
import { xml } from "@codemirror/lang-xml";
import { sql, MySQL, PostgreSQL } from "@codemirror/lang-sql";
import { java } from "@codemirror/lang-java";
import { rust } from "@codemirror/lang-rust";
import { cpp } from "@codemirror/lang-cpp";
import { lezer } from "@codemirror/lang-lezer";
import { php } from "@codemirror/lang-php";
import { liquid } from "@codemirror/lang-liquid";
import { wast } from "@codemirror/lang-wast";
import { vue } from "@codemirror/lang-vue";
import { angular } from "@codemirror/lang-angular";
import { nix } from "@replit/codemirror-lang-nix";
import { svelte } from "@replit/codemirror-lang-svelte";
import { csharp } from "@replit/codemirror-lang-csharp";
import { solidity } from "@replit/codemirror-lang-solidity";

import { apl } from "@codemirror/legacy-modes/mode/apl";
import { asciiArmor } from "@codemirror/legacy-modes/mode/asciiarmor";
// import { asn1 } from '@codemirror/legacy-modes/mode/asn1';
import { asterisk } from "@codemirror/legacy-modes/mode/asterisk";
import { brainfuck } from "@codemirror/legacy-modes/mode/brainfuck";
import {
  c,
  scala,
  kotlin,
  shader,
  nesC,
  objectiveC,
  objectiveCpp,
  squirrel,
  ceylon,
  dart,
} from "@codemirror/legacy-modes/mode/clike";
import { clojure } from "@nextjournal/lang-clojure";
import { cmake } from "@codemirror/legacy-modes/mode/cmake";
import { cobol } from "@codemirror/legacy-modes/mode/cobol";
import { coffeeScript } from "@codemirror/legacy-modes/mode/coffeescript";
import { commonLisp } from "@codemirror/legacy-modes/mode/commonlisp";
import { crystal } from "@codemirror/legacy-modes/mode/crystal";
import { cypher } from "@codemirror/legacy-modes/mode/cypher";
import { d } from "@codemirror/legacy-modes/mode/d";
import { diff } from "@codemirror/legacy-modes/mode/diff";
import { dockerFile } from "@codemirror/legacy-modes/mode/dockerfile";
import { dtd } from "@codemirror/legacy-modes/mode/dtd";
import { dylan } from "@codemirror/legacy-modes/mode/dylan";
import { ebnf } from "@codemirror/legacy-modes/mode/ebnf";
import { ecl } from "@codemirror/legacy-modes/mode/ecl";
import { eiffel } from "@codemirror/legacy-modes/mode/eiffel";
import { elm } from "@codemirror/legacy-modes/mode/elm";
import { erlang } from "@codemirror/legacy-modes/mode/erlang";
import { factor } from "@codemirror/legacy-modes/mode/factor";
import { fcl } from "@codemirror/legacy-modes/mode/fcl";
import { forth } from "@codemirror/legacy-modes/mode/forth";
import { fortran } from "@codemirror/legacy-modes/mode/fortran";
import { gas } from "@codemirror/legacy-modes/mode/gas";
import { gherkin } from "@codemirror/legacy-modes/mode/gherkin";
import { go } from "@codemirror/legacy-modes/mode/go";
import { groovy } from "@codemirror/legacy-modes/mode/groovy";
import { haskell } from "@codemirror/legacy-modes/mode/haskell";
import { haxe } from "@codemirror/legacy-modes/mode/haxe";
import { http } from "@codemirror/legacy-modes/mode/http";
import { idl } from "@codemirror/legacy-modes/mode/idl";
import { jinja2 } from "@codemirror/legacy-modes/mode/jinja2";
import { julia } from "@codemirror/legacy-modes/mode/julia";
import { liveScript } from "@codemirror/legacy-modes/mode/livescript";
import { lua } from "@codemirror/legacy-modes/mode/lua";
import { mathematica } from "@codemirror/legacy-modes/mode/mathematica";
import { mbox } from "@codemirror/legacy-modes/mode/mbox";
import { mirc } from "@codemirror/legacy-modes/mode/mirc";
// import { mllike } from '@codemirror/legacy-modes/mode/mllike';
import { modelica } from "@codemirror/legacy-modes/mode/modelica";
import { mscgen } from "@codemirror/legacy-modes/mode/mscgen";
import { mumps } from "@codemirror/legacy-modes/mode/mumps";
import { nginx } from "@codemirror/legacy-modes/mode/nginx";
import { nsis } from "@codemirror/legacy-modes/mode/nsis";
import { ntriples } from "@codemirror/legacy-modes/mode/ntriples";
import { octave } from "@codemirror/legacy-modes/mode/octave";
import { oz } from "@codemirror/legacy-modes/mode/oz";
import { pascal } from "@codemirror/legacy-modes/mode/pascal";
import { perl } from "@codemirror/legacy-modes/mode/perl";
import { pig } from "@codemirror/legacy-modes/mode/pig";
import { powerShell } from "@codemirror/legacy-modes/mode/powershell";
import { properties } from "@codemirror/legacy-modes/mode/properties";
import { protobuf } from "@codemirror/legacy-modes/mode/protobuf";
import { puppet } from "@codemirror/legacy-modes/mode/puppet";
import { q } from "@codemirror/legacy-modes/mode/q";
import { r } from "@codemirror/legacy-modes/mode/r";
// import { rpm } from '@codemirror/legacy-modes/mode/rpm';
import { ruby } from "@codemirror/legacy-modes/mode/ruby";
import { sas } from "@codemirror/legacy-modes/mode/sas";
import { scheme } from "@codemirror/legacy-modes/mode/scheme";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { sieve } from "@codemirror/legacy-modes/mode/sieve";
// import { mode } from '@codemirror/legacy-modes/mode/simple-mode';
import { smalltalk } from "@codemirror/legacy-modes/mode/smalltalk";
import { solr } from "@codemirror/legacy-modes/mode/solr";
import { sparql } from "@codemirror/legacy-modes/mode/sparql";
import { spreadsheet } from "@codemirror/legacy-modes/mode/spreadsheet";
import { stex } from "@codemirror/legacy-modes/mode/stex";
import { stylus } from "@codemirror/legacy-modes/mode/stylus";
import { swift } from "@codemirror/legacy-modes/mode/swift";
import { tcl } from "@codemirror/legacy-modes/mode/tcl";
import { textile } from "@codemirror/legacy-modes/mode/textile";
import { tiddlyWiki } from "@codemirror/legacy-modes/mode/tiddlywiki";
import { tiki } from "@codemirror/legacy-modes/mode/tiki";
import { toml } from "@codemirror/legacy-modes/mode/toml";
import { troff } from "@codemirror/legacy-modes/mode/troff";
// import { cfg } from '@codemirror/legacy-modes/mode/ttcn-cfg';
import { ttcn } from "@codemirror/legacy-modes/mode/ttcn";
import { turtle } from "@codemirror/legacy-modes/mode/turtle";
import { vb } from "@codemirror/legacy-modes/mode/vb";
import { vbScript } from "@codemirror/legacy-modes/mode/vbscript";
import { velocity } from "@codemirror/legacy-modes/mode/velocity";
import { verilog } from "@codemirror/legacy-modes/mode/verilog";
import { vhdl } from "@codemirror/legacy-modes/mode/vhdl";
import { webIDL } from "@codemirror/legacy-modes/mode/webidl";
import { xQuery } from "@codemirror/legacy-modes/mode/xquery";
import { yacas } from "@codemirror/legacy-modes/mode/yacas";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import { z80 } from "@codemirror/legacy-modes/mode/z80";
import { Button } from "reactstrap";

export const langs = {
  apl: () => StreamLanguage.define(apl),
  asciiArmor: () => StreamLanguage.define(asciiArmor),
  // asn1: () => StreamLanguage.define(asn1),
  asterisk: () => StreamLanguage.define(asterisk),
  // clike: () => StreamLanguage.define(clike),
  c: () => StreamLanguage.define(c),
  csharp: () => csharp(),
  scala: () => StreamLanguage.define(scala),
  solidity: () => solidity,
  kotlin: () => StreamLanguage.define(kotlin),
  shader: () => StreamLanguage.define(shader),
  nesC: () => StreamLanguage.define(nesC),
  objectiveC: () => StreamLanguage.define(objectiveC),
  objectiveCpp: () => StreamLanguage.define(objectiveCpp),
  squirrel: () => StreamLanguage.define(squirrel),
  ceylon: () => StreamLanguage.define(ceylon),
  dart: () => StreamLanguage.define(dart),
  cmake: () => StreamLanguage.define(cmake),
  cobol: () => StreamLanguage.define(cobol),
  commonLisp: () => StreamLanguage.define(commonLisp),
  crystal: () => StreamLanguage.define(crystal),
  cypher: () => StreamLanguage.define(cypher),
  d: () => StreamLanguage.define(d),
  diff: () => StreamLanguage.define(diff),
  dtd: () => StreamLanguage.define(dtd),
  dylan: () => StreamLanguage.define(dylan),
  ebnf: () => StreamLanguage.define(ebnf),
  ecl: () => StreamLanguage.define(ecl),
  eiffel: () => StreamLanguage.define(eiffel),
  elm: () => StreamLanguage.define(elm),
  factor: () => StreamLanguage.define(factor),
  fcl: () => StreamLanguage.define(fcl),
  forth: () => StreamLanguage.define(forth),
  fortran: () => StreamLanguage.define(fortran),
  gas: () => StreamLanguage.define(gas),
  gherkin: () => StreamLanguage.define(gherkin),
  groovy: () => StreamLanguage.define(groovy),
  haskell: () => StreamLanguage.define(haskell),
  haxe: () => StreamLanguage.define(haxe),
  http: () => StreamLanguage.define(http),
  idl: () => StreamLanguage.define(idl),
  jinja2: () => StreamLanguage.define(jinja2),
  mathematica: () => StreamLanguage.define(mathematica),
  mbox: () => StreamLanguage.define(mbox),
  mirc: () => StreamLanguage.define(mirc),
  modelica: () => StreamLanguage.define(modelica),
  mscgen: () => StreamLanguage.define(mscgen),
  mumps: () => StreamLanguage.define(mumps),
  nsis: () => StreamLanguage.define(nsis),
  ntriples: () => StreamLanguage.define(ntriples),
  octave: () => StreamLanguage.define(octave),
  oz: () => StreamLanguage.define(oz),
  pig: () => StreamLanguage.define(pig),
  properties: () => StreamLanguage.define(properties),
  protobuf: () => StreamLanguage.define(protobuf),
  puppet: () => StreamLanguage.define(puppet),
  q: () => StreamLanguage.define(q),
  sas: () => StreamLanguage.define(sas),
  sass: () => sass(),
  liquid: () => liquid(),
  mermaid: () => mermaid(),
  nix: () => nix(),
  svelte: () => svelte(),
  sieve: () => StreamLanguage.define(sieve),
  smalltalk: () => StreamLanguage.define(smalltalk),
  solr: () => StreamLanguage.define(solr),
  sparql: () => StreamLanguage.define(sparql),
  spreadsheet: () => StreamLanguage.define(spreadsheet),
  stex: () => StreamLanguage.define(stex),
  textile: () => StreamLanguage.define(textile),
  tiddlyWiki: () => StreamLanguage.define(tiddlyWiki),
  tiki: () => StreamLanguage.define(tiki),
  troff: () => StreamLanguage.define(troff),
  ttcn: () => StreamLanguage.define(ttcn),
  turtle: () => StreamLanguage.define(turtle),
  velocity: () => StreamLanguage.define(velocity),
  verilog: () => StreamLanguage.define(verilog),
  vhdl: () => StreamLanguage.define(vhdl),
  webIDL: () => StreamLanguage.define(webIDL),
  xQuery: () => StreamLanguage.define(xQuery),
  yacas: () => StreamLanguage.define(yacas),
  z80: () => StreamLanguage.define(z80),

  wast,
  javascript,
  jsx: () => javascript({ jsx: true }),
  typescript: () => javascript({ typescript: true }),
  tsx: () => javascript({ jsx: true, typescript: true }),
  vue: () => vue(),
  angular: () => angular(),
  json,
  html,
  css,
  python,
  markdown: () =>
    markdown({ base: markdownLanguage, codeLanguages: languages }),
  xml,
  sql,
  mysql: () => sql({ dialect: MySQL }),
  pgsql: () => sql({ dialect: PostgreSQL }),
  java,
  rust,
  cpp,
  // clike: () => StreamLanguage.define(clike),
  // clike: () => clike({ }),
  lezer,
  php,
  go: () => StreamLanguage.define(go),
  shell: () => StreamLanguage.define(shell),
  lua: () => StreamLanguage.define(lua),
  swift: () => StreamLanguage.define(swift),
  tcl: () => StreamLanguage.define(tcl),
  yaml: () => StreamLanguage.define(yaml),
  vb: () => StreamLanguage.define(vb),
  powershell: () => StreamLanguage.define(powerShell),
  brainfuck: () => StreamLanguage.define(brainfuck),
  stylus: () => StreamLanguage.define(stylus),
  erlang: () => StreamLanguage.define(erlang),
  nginx: () => StreamLanguage.define(nginx),
  perl: () => StreamLanguage.define(perl),
  ruby: () => StreamLanguage.define(ruby),
  pascal: () => StreamLanguage.define(pascal),
  livescript: () => StreamLanguage.define(liveScript),
  less: () => less(),
  scheme: () => StreamLanguage.define(scheme),
  toml: () => StreamLanguage.define(toml),
  vbscript: () => StreamLanguage.define(vbScript),
  clojure: () => clojure(),
  coffeescript: () => StreamLanguage.define(coffeeScript),
  julia: () => StreamLanguage.define(julia),
  dockerfile: () => StreamLanguage.define(dockerFile),
  r: () => StreamLanguage.define(r),
};

const myTheme = createTheme({
  theme: "dark",
  settings: {
    background: "#ffffff",
    backgroundImage: "",
    foreground: "#75baff",
    caret: "#5d00ff",
    selection: "#036dd626",
    selectionMatch: "#036dd626",
    lineHighlight: "#8a91991a",
    gutterBackground: "#fff",
    gutterForeground: "#8a919966",
  },
  styles: [
    { tag: t.comment, color: "#787b8099" },
    { tag: t.variableName, color: "#0080ff" },
    { tag: [t.string, t.special(t.brace)], color: "#5c6166" },
    { tag: t.number, color: "#5c6166" },
    { tag: t.bool, color: "#5c6166" },
    { tag: t.null, color: "#5c6166" },
    { tag: t.keyword, color: "#5c6166" },
    { tag: t.operator, color: "#5c6166" },
    { tag: t.className, color: "#5c6166" },
    { tag: t.definition(t.typeName), color: "#5c6166" },
    { tag: t.typeName, color: "#5c6166" },
    { tag: t.angleBracket, color: "#5c6166" },
    { tag: t.tagName, color: "#5c6166" },
    { tag: t.attributeName, color: "#5c6166" },
  ],
});
const extensions = [javascript({ jsx: true })];

export default function App() {
  const [value, setValue] = useState("// Write your code here");
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [successMsg, setSuccessMsg] = useState("");
  const langNames = Object.keys(langs);
  console.log("ssss langNames", langNames);
  const handleEditorChange = (value) => {
    setSuccessMsg("");
    setValue(value);
  };

  const items = [
    {
      id: 1,
      language: "javascript",
      description:
        "A company uses a Node.js application that checks several data sources for requested information. Since each request to a data source is performance heavy, the application should try to check the next source only if the request failed at the current data source. That functionality was extracted into the firstSuccessfulPromise function",
      testCase: [
        {
          case: 'firstSuccessfulPromise([\n  new Promise((resolve, reject) => reject()),\n  new Promise((resolve, reject) => resolve("Success!"))\n]).then(result => console.log(result));  // Output: "Success!"\n',
        },
        {
          case: "firstSuccessfulPromise([\n  new Promise((resolve, reject) => reject()),\n  new Promise((resolve, reject) => reject())\n]).then(result => console.log(result));  // Output: undefined\n",
        },
        {
          case: 'firstSuccessfulPromise([\n  new Promise((resolve, reject) => resolve("First Success!")),\n  new Promise((resolve, reject) => resolve("Second Success!"))\n]).then(result => console.log(result));  // Output: "First Success!"\n',
        },
      ],
      defaultCode: "function simulate(arr) {// code here }",
    },
    {
      id: 2,
      language: "angular",
      description:
        "Your company is analyzing malware which targets numerical record files. The malware uses a sliding window over the array of numbers in a file, and tries to match the following pattern: Tl, -, -, X, -, -, -, Tr. The entire window is moved so that 'X' passes through all the values and is compared to the numbers at the 'Tl' and 'Tr' locations, which are positioned at a constant offset to 'X'. The malware has the following rules: If the value at the 'Tl' or 'Tr' position of the pattern is bigger or equal to the value at the 'X’ position, the malware replaces the value at 'X' with 0. If the value at the 'Tl' or 'Tr' offset is out of bounds, then the value at 'X' is only compared to the other existing value. The record is processed in two stages: first, all the positions that should be set to 0 are located, using the original values for comparison. Only after all positions have been identified do they get set to 0. For example, if the values in a record file are the following: [1, 2, 0, 5, 0, 2, 4, 3, 3, 3], the expected values after the malware runs are: [1, 0, 0, 5, 0, 0, 0, 3, 3, 0]. In this example, both '2's, the '4' and the last '3' were replaced by 0. Implement the simulate function so that the malware behavior is replicated for further study.",

      testCase: [
        {
          case: "test('Basic functionality test case', () => {\n  const input = [1, 2, 0, 5, 0, 2, 4, 3, 3, 3];\n  const expected = [1, 0, 0, 5, 0, 0, 0, 3, 3, 0];\n  expect(simulate(input)).toEqual(expected)\n})",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      defaultCode: "function average(a,b) {return (a + b) / 2; } ",
    },
    {
      id: 3,
      description:
        "A gaming company is working on a platformer game. They need a function that will compute the character's final speed, given a map and a starting speed. The terrain on which the game character moves forward is made from various pieces of land placed together. Implement the function calculateFinalSpeed which takes the initial speed of the character, and an array of degrees of inclination that represent the uneven terrain.",
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "jsx",
      defaultCode: "hii",
    },
    {
      id: 4,
      description:
        'An image gallery is a set of images with corresponding remove buttons. This is the HTML code for a gallery with two images: <div> <div class="image"> <img src="https://bit.ly/3xXPxPR"> <button class="remove">X</button> </div> <div class="image"> <img src="https://bit.ly/4de3sQr"> <button class="remove">X</button> </div> </div> Implement the ImageGallery component that accepts a links prop and renders the gallery described above so that the first item in the links prop is the src attribute of the first image in the gallery. It should also implement the following logic: When the button is clicked, the image that is in the same div as the button should be removed from the gallery. For example, after the first image has been removed from the gallery above, its HTML code should look like this: <div> <div class="image"> <img src="https://bit.ly/4de3sQr"> <button class="remove">X</button> </div> </div>',
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "html",
      defaultCode:
        "import { useState } from 'react';\n\nexport default function Home() {\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <h1>Welcome to Next.js!</h1>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increase Count</button>\n    </div>\n  );\n}",
    },
    {
      id: 5,
      description:
        "An application for testing programmers contains an initial question version and newer question versions, represented by the following schema: TABLE questions id INTEGER NOT NULL PRIMARY KEY, name VARCHAR(50) NOT NULL, childId INTEGER, FOREIGN KEY (childId) REFERENCES questions(id). The latest question version has childId set to NULL, other question versions will have childId set to the next version. Each question version can be a child of only one other question version. Finish the findLatestVersion stored procedure so that it returns the latest question version.",
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "css",
      defaultCode:
        "import { useState } from 'react';\n\nexport default function Home() {\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <h1>Welcome to Next.js!</h1>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increase Count</button>\n    </div>\n  );\n}",
    },
    {
      id: 6,
      description:
        "An application for testing programmers contains an initial question version and newer question versions, represented by the following schema:\nTABLE questions\n  id INTEGER NOT NULL PRIMARY KEY\n  name VARCHAR(50) NOT NULL\n  childId INTEGER\n  FOREIGN KEY (childId) REFERENCES questions(id)\n\nThe latest question version has childId set to NULL, other question versions will have childId set to the next version. Each question version can be a child of only one other question version.\nFinish the findLatestVersion stored procedure so that it returns the latest question version.",
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "sql",
      defaultCode:
        "import { useState } from 'react';\n\nexport default function Home() {\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <h1>Welcome to Next.js!</h1>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increase Count</button>\n    </div>\n  );\n}",
    },
    {
      id: 7,
      description:
        "A turn-based strategy game has a grid with water and land. The grid contains a true value where it's water and false where it's land.\nThe player controls a boat unit with a particular movement pattern. It can only move to fixed destinations from its current position as shown in the image below:\nThe boat can only move in a direct path through water to the possible destinations, so a destination will become unreachable if there is land in the way.\nImplement the canTravelTo function, that checks whether a destination is reachable by the boat. It should return true for destinations that are reachable according to the pattern above, and false for unreachable or out of bounds destinations which are outside the grid.",
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "jsx",
      defaultCode:
        "import { useState } from 'react';\n\nexport default function Home() {\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <h1>Welcome to Next.js!</h1>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increase Count</button>\n    </div>\n  );\n}",
    },
    {
      id: 8,
      description:
        'Finish the ShoppingList component. The component will receive an array of strings as the items array. It should render one li element per item in the array.\nFor example, if the items array is ["Bread", "Eggs", "Milk"], the rendered HTML should look like:\n<ul>\n  <li>Bread</li>\n  <li>Eggs</li>\n  <li>Milk</li>\n</ul>',
      testCase: [
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
        {
          case: "def test_reverse_string(self):self.assertEqual(reverse_string('hello'), 'olleh')self.assertEqual(reverse_string('world'), 'dlrow') self.assertEqual(reverse_string('Python'), 'nohtyP') self.assertEqual(reverse_string(''), '') self.assertEqual(reverse_string('a'), 'a')",
        },
      ],
      language: "jsx",
      defaultCode:
        "import { useState } from 'react';\n\nexport default function Home() {\n  const [count, setCount] = useState(0);\n  return (\n    <div>\n      <h1>Welcome to Next.js!</h1>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increase Count</button>\n    </div>\n  );\n}",
    },
  ];

  const handleNext = () => {
    setValue("// Write your code here");
    setSuccessMsg("");
    if (currentIndex < items.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    setSuccessMsg("");
    setValue("// Write your code here");
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const codeRun = async function () {
    setSuccessMsg("");
    setLoading(true);
    let body = {
      id: currentItem.id,
      message: value,
    };
    try {
      const response = await axios.post(
        `https://imenso.in:5053/message`,
        body
      );
      const result = await response;
      console.log("result", result);
      setSuccessMsg(
        result.data.data.result == 1
          ? "code complie succesfully"
          : "Code is Incorrect"
      );
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  useEffect(() => {
    console.log("ssss", value);
  }, [value]);

  useEffect(() => {
    items.map((obj, index) => {
      if (index == currentIndex) {
        setCurrentItem(obj);
      }
    });
  }, [currentIndex]);

  return (
    <div>
      <div>
        <h1> Language :-{currentItem?.language}</h1>
        <h2>
          {" "}
          Question {currentItem.id}
          {")-"}
          {currentItem?.description}
        </h2>
        <div style={{ marginBottom: "5px", display: "flex" }}>
          <button
            type="button"
            className=" m-2 p-2 btn btn-primary"
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            color="primary"
            type="button"
            className=" m-2 btn btn-primary"
            disabled={currentIndex === items.length - 1}
          >
            Next
          </button>

          <button
            onClick={codeRun}
            className="m-2 btn btn-success"
            disabled={currentIndex === items.length - 1}
          >
            {loading ? (
              <div class="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Run"
            )}
          </button>
          <div className=" m-2">{successMsg}</div>
        </div>
      </div>
      {/* {loading ? (
        <div class="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : ( */}
      <CodeMirror
        value={currentItem.defaultCode}
        height="200px"
        // theme={myTheme}
        extensions={[loadLanguage(currentItem.language)]}
        onChange={handleEditorChange}
      />
      {/* )} */}
    </div>
  );
}
